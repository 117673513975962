import { Table, TimeFromNow, FileStatus, results } from '@mdc/ui';
import { ENGINE_STATUS, ROUTES } from '@mdc/constants';
import { i18nUtility, metadefenderService } from '@mdc/services';
import { useTranslation } from 'react-i18next';
import { OutboundLink } from 'gatsby-plugin-google-analytics';

import './SubmissionHistory.scss';

const { Alert } = results;

const ROWS_PER_REQUEST = 50;

const SubmissionHistory = () => {
    const { t, ready } = useTranslation();

    const tableColumns = [
        {
            Header: t('File name'),
            accessor: 'fileInfo'
        },
        {
            Header: t('Scan time'),
            accessor: 'scanTime',
            component: TimeFromNow
        },
        {
            Header: t('MetaDefender results'),
            accessor: 'results',
            component: FileStatus,
        }
    ];

    const mapFileResultToStatus = (resultCode) => {
        switch (resultCode) {
            case ENGINE_STATUS.NO_THREAT:
                return 'clean';
            case ENGINE_STATUS.INFECTED:
                return 'infected';
            case ENGINE_STATUS.ABORTED:
                return 'aborted';
            default:
                return 'failed';
        }
    };

    const processRawRowData = (rawData) => {
        const fileURL = `${ROUTES.results.href}/file/${rawData.data_id}/regular/overview`;
        // eslint-disable-next-line camelcase
        let fileName = rawData?.file_info?.display_name?.toLowerCase() || ' ';
        // eslint-disable-next-line camelcase
        const fileExtension = typeof rawData?.file_info?.file_type_extension === 'string' ? rawData.file_info.file_type_extension : null;
        // eslint-disable-next-line security/detect-unsafe-regex
        const extensionRegex = /(?:\.([^.]+))?$/;
        let extensionMismatchMessage = '';

        if (fileExtension && fileExtension !== extensionRegex.exec(fileName)[1]) {
            extensionMismatchMessage = <span>
                {t('There is a mismatch between this file\'s type and its extension.')}
                {' '}
                {t('The file type is {{fileType}}', { fileType: fileExtension })}
                {'.'}
            </span>;
        }

        try {
            fileName = decodeURIComponent(rawData.file_info.display_name);
        } catch (e) {
            // Ignore
        }

        const fileInfo = (
            <div>
                <OutboundLink href={i18nUtility.rebuildUrlWithLangPrefix(fileURL)} target='_blank' rel='noopener noreferrer'>
                    {fileName}
                </OutboundLink>
                <span>
                    {rawData.file_info.sha1}
                </span>
                {extensionMismatchMessage && <Alert message={extensionMismatchMessage} type='warning' />}
            </div>);
        const scanTime = rawData.scan_results.start_time;

        let scanResult = mapFileResultToStatus(rawData.scan_results.scan_all_result_i);

        return { fileInfo, scanTime, results: scanResult };
    };

    const getTableData = ({ offset }) => {
        const [historyRequestPromise] = metadefenderService.apikey.getScanHistory({
            limit: ROWS_PER_REQUEST,
            offset
        });
        return historyRequestPromise;
    };


    const getNewTableData = (tableManagerState) => {
        const offset = (tableManagerState.currentPage - 1) * ROWS_PER_REQUEST;
        /* eslint-disable no-async-promise-executor*/
        return new Promise(async (resolve, reject) => {
            try {
                const response = await getTableData({
                    offset
                });
                const rawData = response.data.data;
                if (rawData.length) {
                    const processedData = rawData.map((data) => {
                        return processRawRowData(data);
                    });
                    resolve(processedData);
                } else {
                    reject([]);
                }
            } catch (error) {
                reject(error);
            }
        });
    };

    if (!ready) {
        return null;
    }
    return (
        <>
            <h1>{t('Submission History')}</h1>
            <Table
                useSorting={false}
                data={[]}
                columns={tableColumns}
                useInfiniteScroll
                getNewDataHandler={getNewTableData} />
        </>
    );
};


export default SubmissionHistory;
