/* eslint-disable camelcase */
// eslint-disable-next-line no-unused-vars
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { COLORS } from './Colors';
import ContentLoader from 'react-content-loader';
import { Row, Col } from 'react-bootstrap';
import { ApexChart, InternalLink } from '@mdc/ui';
import { useTranslation } from 'react-i18next';
import { dayjs, UtilsFunctions } from '@mdc/services';

import './Dashboard.scss';

const SERVICES_MAP = (t) => ({
    fileScan: {
        colors: [COLORS.areaColor, COLORS.infectedColor],
        yCategories: (data) => data?.statistics?.map((s) => ({ from: s.from, to: s.to })) || [],
        series: [
            {
                type: 'area',
                name: t('Files Processed'),
                data: (data) => data?.statistics?.map((s) => (s.file_scan?.allowed_files || 0) + (s.file_scan?.blocked_files || 0)) || []
            },
            {
                type: 'column',
                name: t('Files Blocked'),
                data: (data) => data?.statistics?.map((s) => s.file_scan?.blocked_files || 0) || []
            }
        ],
        badges: [
            {
                title: t('Allowed / Blocked'),
                values: [
                    {
                        color: COLORS.cleanColor,
                        value: (data) => data?.statistics?.reduce((total, current) => total + (current.file_scan?.allowed_files || 0), 0)
                    },
                    {
                        color: COLORS.infectedColor,
                        value: (data) => data?.statistics?.reduce((total, current) => total + (current.file_scan?.blocked_files || 0), 0)
                    }
                ]
            },
            {
                title: t('Sanitized'),
                values: [
                    {
                        color: COLORS.sanitizedColor,
                        value: (data) => data?.statistics?.reduce((total, current) => total + (current.file_scan?.deep_cdr?.total_sanitized_ok || 0), 0)
                    }
                ]
            }
        ]
    },
    sandbox: {
        colors: [COLORS.areaColor, COLORS.suspiciousColor, COLORS.infectedColor],
        yCategories: (data) => data?.statistics?.map((s) => ({ from: s.from, to: s.to })) || [],
        series: [
            {
                type: 'area',
                name: t('Total sandbox scan'),
                data: (data) => data?.statistics?.map((s) => s.sandbox?.total || 0) || []
            },
            {
                type: 'column',
                name: t('Suspicious'),
                data: (data) => data?.statistics?.map((s) => s.sandbox?.total_suspicious || 0) || []
            },
            {
                type: 'column',
                name: t('Infected'),
                data: (data) => data?.statistics?.map((s) => s.sandbox?.total_infected || 0) || []
            }
        ],
        badges: [
            {
                title: t('No Threat Detected / Suspicious / Infected'),
                values: [
                    {
                        color: COLORS.cleanColor,
                        value: (data) => data?.statistics?.reduce((total, current) => total + (current.sandbox?.total_clean || 0), 0)
                    },
                    {
                        color: COLORS.suspiciousColor,
                        value: (data) => data?.statistics?.reduce((total, current) => total + (current.sandbox?.total_suspicious || 0), 0)
                    },
                    {
                        color: COLORS.infectedColor,
                        value: (data) => data?.statistics?.reduce((total, current) => total + (current.sandbox?.total_infected || 0), 0)
                    }
                ]
            }
        ]
    },
    fileReputation: {
        colors: [COLORS.areaColor, COLORS.cleanColor, COLORS.infectedColor],
        yCategories: (data) => data?.statistics?.map((s) => ({ from: s.from, to: s.to })) || [],
        series: [
            {
                type: 'area',
                name: t('Total file checked'),
                data: (data) => data?.statistics?.map((s) => (s.file_reputation?.found || 0) + (s.file_reputation?.missing || 0)) || []
            },
            {
                type: 'column',
                name: t('No Threat Detected'),
                data: (data) => data?.statistics?.map((s) => s.file_reputation?.clean || 0) || []
            },
            {
                type: 'column',
                name: t('Infected'),
                data: (data) => data?.statistics?.map((s) => s.file_reputation?.infected || 0) || []
            }
        ],
        badges: [
            {
                title: t('Found / Not Found'),
                values: [
                    {
                        color: COLORS.foundColor,
                        value: (data) => data?.statistics?.reduce((total, current) => total + (current.file_reputation?.found || 0), 0)
                    },
                    {
                        color: COLORS.notFoundColor,
                        value: (data) => data?.statistics?.reduce((total, current) => total + (current.file_reputation?.missing || 0), 0)
                    }
                ]
            },
            {
                title: t('No Threat Detected / Infected'),
                values: [
                    {
                        color: COLORS.cleanColor,
                        value: (data) => data?.statistics?.reduce((total, current) => total + (current.file_reputation?.clean || 0), 0)
                    },
                    {
                        color: COLORS.infectedColor,
                        value: (data) => data?.statistics?.reduce((total, current) => total + (current.file_reputation?.infected || 0), 0)
                    }
                ]
            }
        ]
    },
    address: {
        colors: [COLORS.areaColor, COLORS.infectedColor],
        yCategories: (data) => data?.statistics?.map((s) => ({ from: s.from, to: s.to })) || [],
        series: [
            {
                type: 'area',
                name: t('Total reputation'),
                data: (data) => data?.statistics?.map((s) => (s.ip_reputation?.found || 0) + (s.ip_reputation?.missing || 0) + (s.url_reputation?.found || 0) + (s.url_reputation?.missing || 0) + (s.domain_reputation?.found || 0) + (s.domain_reputation?.missing || 0)) || []
            },
            {
                type: 'column',
                name: t('Malicious'),
                data: (data) => data?.statistics?.map((s) => (s.ip_reputation?.infected || 0) + (s.url_reputation?.infected || 0) + (s.domain_reputation?.infected || 0)) || []
            }
        ],
        badges: [
            {
                title: t('IP'),
                subTitle: t('Trustworthy / Malicious'),
                values: [
                    {
                        color: COLORS.cleanColor,
                        value: (data) => data?.statistics?.reduce((total, current) => total + (current.ip_reputation?.clean || 0), 0)
                    },
                    {
                        color: COLORS.infectedColor,
                        value: (data) => data?.statistics?.reduce((total, current) => total + (current.ip_reputation?.infected || 0), 0)
                    }
                ]
            },
            {
                title: t('Domain'),
                subTitle: t('Trustworthy / Malicious'),
                values: [
                    {
                        color: COLORS.cleanColor,
                        value: (data) => data?.statistics?.reduce((total, current) => total + (current.domain_reputation?.clean || 0), 0)
                    },
                    {
                        color: COLORS.infectedColor,
                        value: (data) => data?.statistics?.reduce((total, current) => total + (current.domain_reputation?.infected || 0), 0)
                    }
                ]
            },
            {
                title: t('URL'),
                subTitle: t('Trustworthy / Malicious'),
                values: [
                    {
                        color: COLORS.cleanColor,
                        value: (data) => data?.statistics?.reduce((total, current) => total + (current.url_reputation?.clean || 0), 0)
                    },
                    {
                        color: COLORS.infectedColor,
                        value: (data) => data?.statistics?.reduce((total, current) => total + (current.url_reputation?.infected || 0), 0)
                    }
                ]
            }
        ]
    }
});

const Dashboard = ({ data, service }) => {
    const { t, ready } = useTranslation();
    const serviceMapped = useMemo(() => SERVICES_MAP(t)[service.key] || SERVICES_MAP(t).fileScan, [service, t]);

    const chartSeries = useMemo(() => {
        return data ? serviceMapped.series.map((chart) => ({
            ...chart,
            data: chart.data(data)
        })) : undefined;
    }, [serviceMapped, data]);

    const chartX = useMemo(() => {
        const categories = serviceMapped.yCategories(data);
        const categoriesFrom = categories?.map((c) => c.from);
        let timeFormat = 'YYYY-MM-DD HH:mm';

        if (categoriesFrom?.length) {
            // Build time format on Y axis
            const max = categoriesFrom[categoriesFrom.length - 1];
            const min = categoriesFrom[0];
            if (categoriesFrom.length >= 2) {
                const resolutionHours = Math.abs(dayjs.duration(dayjs(categoriesFrom[0]).diff(categoriesFrom[1])).asHours());
                const durationHours = Math.abs(dayjs.duration(dayjs(max).diff(min)).asHours());
                timeFormat = UtilsFunctions.getTimeFormat(resolutionHours, durationHours);
            }
        }

        return { categories, timeFormat };
    }, [serviceMapped, data]);

    const bodyDOM = useMemo(() => {
        return serviceMapped.badges.map((badge) => {
            const goToHref = <InternalLink to='/account/reports/statistics'>{t('Details')}<i className='icon icon-arrow-right' /></InternalLink>;

            return <Col key={badge.title} xs={12} sm={6} md={Math.floor(12 / serviceMapped.badges.length)}>
                <Col xs={12} className='badgeColumn'>
                    <div className='badgeHeader'>
                        <span className='badgeTitle'>
                            {badge.title}
                        </span>
                        <span className={`badgeSubtitle ${!badge.subTitle && 'd-none'}`}>
                            {badge.subTitle}
                        </span>
                    </div>
                    <div className='valueContainer'>
                        {badge.values.map((v, index) => {
                            const value = v.value(data);
                            const addDivider = index < badge.values.length - 1;
                            const loadingWidth = 40 + 40 * Math.random();
                            let keyCounter = 0;

                            return <React.Fragment key={keyCounter++}>
                                {value !== undefined && <span className='valueSpan' style={{ color: v.color }}>{value}</span>}
                                {value === undefined && <ContentLoader
                                    height={21}
                                    width={loadingWidth}
                                    speed={1}
                                >
                                    <rect x='0' y='0' rx='4' ry='4' width={loadingWidth} height='19' />
                                </ContentLoader>}
                                {addDivider && <span className='valueDivider'>/</span>}
                            </React.Fragment>;
                        })}
                    </div>
                    {goToHref && <div className='d-flex flex-row flex-wrap align-items-center justify-content-between mt-2'>
                        <div>
                            {/* once we have the API, display the added numbers after refresh */}
                            -
                        </div>
                        {goToHref}
                    </div>}
                </Col>
            </Col>;
        });
    }, [serviceMapped, data]);

    if (!ready) {
        return;
    }

    return <div className='dashboardReports'>
        <Row className='mb-md-4 mb-3'>
            {bodyDOM}
        </Row>
        <Row className='mt-5'>
            <Col className={'chartCol p-0'}>
                <ApexChart chartSeries={chartSeries} colors={serviceMapped.colors} xAxis={chartX.categories} xFormat={chartX.timeFormat} />
            </Col>
        </Row>
    </div>;
};

Dashboard.propTypes = {
    data: PropTypes.object,
    service: PropTypes.object
};

export default Dashboard;
