import { useTranslation } from 'react-i18next';
import { i18nUtility, metadefenderService } from '@mdc/services';
import { Table, SubmissionStatus } from '@mdc/ui';
import { ROUTES } from '@mdc/constants';
import { OutboundLink } from 'gatsby-plugin-google-analytics';

import './VulnerabilitySubmissions.scss';

const VulnerabilitySubmissions = () => {
    const { t, ready } = useTranslation();

    const tableColumns = [
        {
            Header: t('Submission'),
            accessor: 'submission'
        },
        {
            Header: t('Key'),
            accessor: 'key'
        },
        {
            Header: t('Status'),
            accessor: 'status',
            component: SubmissionStatus
        }
    ];

    const getNormalizedSubmissionStatus = (status) => {
        return status.toLowerCase();
    };

    const processRawRowData = (rawData) => {
        const URL = `${ROUTES.results.href}/file/${rawData.hash}/hash/vulnerabilities/`;
        const submissionPrefix = rawData.isCVE ? t('Associate binary with CVE:') : t('Submit binary for analysis:');
        const submission = (
            <div>
                <OutboundLink href={i18nUtility.rebuildUrlWithLangPrefix(URL)} target="_blank" rel="noopener noreferrer">
                    {`${submissionPrefix} ${rawData.fileName}`}
                </OutboundLink>
                <span>
                    {rawData.hash}
                </span>
            </div>);
        const key = rawData.key;
        const status = getNormalizedSubmissionStatus(rawData.status);

        return { submission, key, status };
    };

    const getTableData = () => {
        const [historyRequestPromise] = metadefenderService.apikey.getVulnerability();
        return historyRequestPromise;
    };

    const getNewTableData = (tableManagerState) => {
        return new Promise((resolve, reject) => {
            // Current API not support paging, take all data at first table page.
            // Edit below code when API support paging
            if (tableManagerState.currentPage === 1) {
                getTableData().then((response) => {
                    let rawInitialTableData = response.data.data;
                    const tableData = Array.isArray(rawInitialTableData) && rawInitialTableData.map((data) => processRawRowData(data));
                    if (tableData.length > 0) {
                        resolve(tableData);
                        return;
                    }
                    reject([]);
                }).catch((error) => reject(error));
            } else {
                reject([]);
            }
        });
    };

    if (!ready) {
        return null;
    }

    return (
        <>
            <h1>{t('Vulnerability submissions')}</h1>
            <Table
                useSorting={false}
                data={[]}
                columns={tableColumns}
                useInfiniteScroll
                getNewDataHandler={getNewTableData}
            />
        </>
    );
};


export default VulnerabilitySubmissions;
