/* eslint-disable no-unreachable */
/* eslint-disable no-useless-escape */
/* eslint-disable camelcase */
import { useState, useEffect, useContext, useMemo } from 'react';
import { UserInfoBar, ApikeyInfo, ActiveLicenses, NotificationContext } from '@mdc/ui';
import { Alert, Row, Col } from 'react-bootstrap';

import PropTypes from 'prop-types';

import { NOTIFICATION_TYPES } from '@mdc/constants';
import { BackendService, dayjs, StoreService, InternalNavigation } from '@mdc/services';
import { useTranslation } from 'react-i18next';
const SSO_ISSUER = 'https://id.opswat.com/';

import './AccountInformation.scss';

const AccountInformation = ({ user }) => {
    const { notify } = useContext(NotificationContext);
    const [isPaidUser, setIspaidUser] = useState(false);
    const [userData, setUserData] = useState(user);
    const [isPaymentPending, setIsPaymentPending] = useState(false);
    const [userHadSubscriptions, setUserHadSubscriptions] = useState(false);
    const [userActiveLicense, setUserActiveLicense] = useState(null);
    const [licenseExpirationDate, setLicenseExpirationDate] = useState(user?.expiration_date);
    const [canceledSubscription, setCanceledSubscription] = useState(false);
    const [invoiceURL, setInvoiceURL] = useState();
    const { t } = useTranslation();
    const userOrganization = useMemo(() => {
        if (!user) {
            return undefined;
        }

        return user?.organization ?? null;
    }, [user]);

    let [nickName, setNickName] = useState(() => userData.nickname || '');

    useEffect(() => {
        (async () => {
            if (!user) {
                return;
            }
            const [subscriptions] = await StoreService.getSubscription();
            const invoiceLink = subscriptions && subscriptions.length && subscriptions[0].invoiceUrl;
            const receiptUrl = subscriptions && subscriptions.length && subscriptions[0].receiptUrl;

            if (invoiceLink && !receiptUrl) {
                setInvoiceURL(invoiceLink);
                setIsPaymentPending(true);
            }
            if (subscriptions) {
                subscriptions?.forEach((item) => item.canceledAt && dayjs(item.canceledAt).isBefore() && setCanceledSubscription(true));
                setUserHadSubscriptions(true);
            }
        })();
    }, [user, canceledSubscription]);

    useEffect(() => {
        if (!canceledSubscription && !userActiveLicense) {
            setLicenseExpirationDate(user?.expiration_date);
            setUserActiveLicense(user?.license);

        }
    }, [userActiveLicense, userData, canceledSubscription, licenseExpirationDate, user]);

    const apikeyActiveLicense = useMemo(() => {
        return <ActiveLicenses
            license={userActiveLicense}
            paidApikeyExpirationDate={licenseExpirationDate}
        />;
    }, [userActiveLicense, user, licenseExpirationDate]);

    const subscriptionsContainer = useMemo(() => {
        return user && userOrganization === null && ((licenseExpirationDate && dayjs(licenseExpirationDate).isAfter()) || userHadSubscriptions) ? (
            <>
                <Row className="separator">
                    <div></div>
                </Row>
                <Row>
                    {apikeyActiveLicense}
                </Row>
            </>
        ) : null;
    }, [user, userOrganization, licenseExpirationDate, userHadSubscriptions, userActiveLicense]);


    useEffect(() => {
        if (!user) {
            return;
        }

        setNickName(user.nickname);
        setIspaidUser(user.paid_user);
        setUserData(user);
    }, [user]);

    useEffect(() => {
        setNickName(userData.nickname);
        setIspaidUser(userData.paid_user);
    }, [userData]);

    const paymentPendingDOM = isPaymentPending ? (
        <Row>
            <Col>
                <Alert variant="info">
                    <span dangerouslySetInnerHTML={{ __html: t('Your apikey will be updated after the payment process is completed. <a href=\"{{invoiceURL}}\" rel=`noopener noreferrer` target=`_blank`>Pay now.</<a>', { invoiceURL: invoiceURL }) }}></span>
                </Alert>
            </Col>
        </Row>
    ) : null;

    const updateNickname = async (nickname) => {
        try {
            const [updateApiKey] = await BackendService.updateApiKey({ nickname });
            let response = await updateApiKey;
            if (response && response.data && response.data.nickname === nickname) {
                setNickName(nickname);
                user.nickname = nickname;
                userData.nickname = nickname;
                return true;
            }
            notify({
                message: t('Request failed. Please try again!'),
                type: NOTIFICATION_TYPES.CRITICAL
            });
            return false;
        } catch (error) {
            const response = error.response;
            if (response && response.data && response.data.code) {
                switch (response.data.code) {
                    case 400022:
                        notify({
                            message: t('You are not logged in'),
                            type: NOTIFICATION_TYPES.WARNING
                        });
                        break;
                    case 400025:
                        notify({
                            message: t('Nickname contains profanities. Please choose another one'),
                            type: NOTIFICATION_TYPES.CRITICAL
                        });
                        break;
                    case 400024:
                        notify({
                            message: t('Nickname already exists. Please choose another one'),
                            type: NOTIFICATION_TYPES.CRITICAL
                        });
                        break;
                    case 400027:
                        notify({
                            message: t('Nickname too long (maximum 20 characters allowed)'),
                            type: NOTIFICATION_TYPES.CRITICAL
                        });
                        break;
                    case 400028:
                        notify({
                            message: `${t('Nickname format is incorrect. Please use only alphanumeric and special characters such as ._ -')}`,
                            type: NOTIFICATION_TYPES.CRITICAL
                        });
                        break;
                    default:
                        notify({
                            message: t('Request failed. Please try again!'),
                            type: NOTIFICATION_TYPES.CRITICAL
                        });
                }
            } else {
                notify({
                    message: t('Request failed. Please try again!'),
                    type: NOTIFICATION_TYPES.CRITICAL
                });
            }
            throw new Error('Request failed. Please try again!');
        }
    };

    const goToLicensing = () => {
        InternalNavigation.navigation('/store');
    };
    return (
        <main>
            <UserInfoBar
                email={user && userData.email}
                nickname={nickName}
                points={user && userData.reputation}
                updateNickname={updateNickname}
                userName={user && `${userData.firstName} ${userData.lastName}`}
                user={user} />
            <Row className="accountInformationInfoLinks">
                <span dangerouslySetInnerHTML={{ __html: t('Visit your <a href=\"{{SSO_ISSUER}}profile\" rel=`noopener noreferrer` target=`_blank`> OPSWAT User Profile</a> to modify your information', { SSO_ISSUER: SSO_ISSUER }) }}></span>
                <span dangerouslySetInnerHTML={{ __html: t('Check out our <a href=\"/leaderboard\">Leaderboard</a> to see where you rank') }}></span>
            </Row>
            {paymentPendingDOM}
            <ApikeyInfo
                apikey={user && userData.apikey}
                dailyFeedLimit={user && userData.limit_feed}
                limitInterval={user && userData.time_interval}
                paidUser={isPaidUser ? 'Yes' : 'No'}
                preventionLimit={user && userData.limit_prevention}
                reputationLimit={user && userData.limit_reputation}
                limitThreatIntelSearch={user && userData?.limit_threat_intel_search}
                sandboxLimit={user && userData.limit_sandbox}
                updateLimits={goToLicensing}
                expirationDate={isPaidUser ? dayjs(user.expiration_date).format('MMM DD YYYY') : false}
                user={user}
                userOrganization={userOrganization}
                throttlingLimit={user && userData.organization?.throttling_limit}
                ssoIssuer={SSO_ISSUER}
            />

            {subscriptionsContainer}
            <div className="usageWrapper"></div>


        </main>
    );
};

AccountInformation.propTypes = {
    user: PropTypes.object.isRequired
};
export default AccountInformation;
