import { useMemo } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Badge, Accordion } from 'react-bootstrap';
import { OutboundLink } from 'gatsby-plugin-google-analytics';
import { InternalLink } from '@mdc/ui';

const SidebarMenuItem = (props) => {
    const { url, icon, label, to, target, badgeLabel, isMainMenuItem, locationPath } = props;

    const isSelected = useMemo(() => to && locationPath?.endsWith(to), [locationPath, to]);

    const classNames = classnames({
        'icon': true,
        [icon]: true
    });

    const listClass = classnames({
        'selectedItem': isSelected && !isMainMenuItem
    });

    const IconItem = icon ? (<i className={classNames}/>) : null;
    const labelSpan = (<span className='menuLabel'>{label}</span>);
    const badge = badgeLabel ? <Badge>{badgeLabel}</Badge> : null;

    const content = useMemo(() => {
        if (url) {
            return (<OutboundLink href={url} target={target} rel='noopener noreferrer'>{IconItem} {labelSpan} {badge}</OutboundLink>);
        } else if (to) {
            return <InternalLink to={to}><span>{IconItem} {labelSpan} {badge}</span></InternalLink>;
        }
    }, [IconItem, labelSpan, badge, to, url, target]);

    return <Accordion.Toggle as='ul' eventKey={label} className='mb-0 pl-0'>
        <li key={label} className={listClass}>
            {content}
        </li>
    </Accordion.Toggle>;

};

SidebarMenuItem.propTypes = {
    label: PropTypes.string,
    icon: PropTypes.string,
    target: PropTypes.string,
    subMenuExpanded: PropTypes.func,
    badgeLabel: PropTypes.any,
    isMainMenuItem: PropTypes.bool,
    locationPath: PropTypes.string,
    url: ({ url, to }, propName, componentName) => {
        if (!url && !to) {
            return new Error(`One of props 'url' or 'to' was not specified in '${componentName}'.`);
        }
        if (url && typeof url !== 'string') {
            return new Error(`The '${propName}' was passed as ${typeof url} but it is required to be of type String`);
        }
    },
    to: ({ url, to }, propName, componentName) => {
        if (!url && !to) {
            return new Error(`One of props 'url' or 'to' was not specified in '${componentName}'.`);
        }
        if (to && typeof to !== 'string') {
            return new Error(`The '${propName}' was passed as ${typeof to} but it is required to be of type String`);
        }
    }
};

export default SidebarMenuItem;
