import { useMemo } from 'react';
import { Col, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { Checkbox } from '@mdc/ui';
import { useTranslation } from 'react-i18next';
import CHECKBOXES_FIELDS from './CheckboxFields';

const NotificationPermission = ({ tag, hasEditAccess, subscription, onOptionClick, isUpdating }) => {
    const { t, ready } = useTranslation();

    const isOptionSelected = (param, type) => {
        let isChecked = false;
        const percentageTag = param.tag.match(/\d+%?/g) ? param.tag.replace(/-\d+%?/g, '') : null;
        if (type === 'email' || type === 'inApp') {
            isChecked = subscription.some((flag) => ((percentageTag && flag.tag.includes(percentageTag)) || (!percentageTag && flag.tag === param.tag)) && flag.delivery.includes(type)) ? true : false;
        } else if (type === 'suboption') {
            isChecked = subscription.some((flag) => flag.tag === param.tag) ? true : false;
        }

        return isChecked;
    };

    const subOptions = useMemo(() => {
        if (!tag?.tagsList || !tag?.percentages) {
            return;
        }

        const percentagesDom = tag.tagsList
            .sort((tag1, tag2) => {
                if (parseInt(tag1.percentage) > parseInt(tag2.percentage)) {
                    return 1;
                }

                return parseInt(tag1.percentage) < parseInt(tag2.percentage) ? -1 : 0;
            })
            .map((flag) => {
                return <Checkbox
                    key={flag.tag}
                    onChange={onOptionClick.bind(null, flag, 'suboption')}
                    isSelected={isOptionSelected(flag, 'suboption')}
                    isDisabled={!hasEditAccess || (!isOptionSelected(flag, 'email') && !isOptionSelected(flag, 'inApp')) || isUpdating}
                    useManualCheck
                >
                    {flag.percentage}
                </Checkbox>;
            });

        return <div className='subOptions'>
            {percentagesDom}
        </div>;
    }, [tag, subscription, isUpdating, hasEditAccess]);

    const checkboxesDom = useMemo(() => {
        return CHECKBOXES_FIELDS(t).map((checkbox) => {
            return <Checkbox
                key={checkbox.key}
                isDisabled={!hasEditAccess || isUpdating}
                onChange={onOptionClick.bind(null, tag, checkbox.key)}
                isSelected={isOptionSelected(tag, checkbox.key)}
                useManualCheck
            >
                {checkbox.label}
            </Checkbox>;
        });
    }, [hasEditAccess, subscription, tag, isUpdating, hasEditAccess]);

    const notificationTag = useMemo(() => {
        const tagList = tag?.tagsList ?? [];
        if (Array.isArray(tagList) && tagList.length > 0) {
            return tagList.filter(({ tag: tagListTag }) => subscription?.some(({ tag: tagSubscription }) => tagListTag === tagSubscription))
                .map((tagItem) => <p key={tagItem.tag} className='description'>{tagItem.tag}</p>);
        }

        return <p className='description'>{tag.tag.replace('IPWhitelistRestriction', 'IPAllowlistRestriction')}</p>;
    }, [subscription, tag]);

    const notificationDescription = useMemo(() => {
        const tagList = tag?.tagsList ?? [];
        if (Array.isArray(tagList) && tagList.length > 0) {
            let description = tagList.filter(({ tag: tagListTag }) => subscription?.some(({ tag: tagSubscription }) => tagListTag === tagSubscription))
                .map((flag) => flag.percentage)
                .reduce((current, prev) => current.concat(prev), [])
                .join(', ') || '%';

            return tag?.description?.replace(/\d+%?/g, description);
        }

        return tag?.description || 'No description';

    }, [subscription, tag]);

    const permissionDom = useMemo(() => {

        return <Row className='tagLi'>
            <Col xs={12} md={6} className='name'>
                <p className='title'>
                    {notificationDescription.includes('removed to') ? notificationDescription.replace('removed to', 'removed from') : notificationDescription.replace('whitelisted', 'allowlisted')}
                </p>
                <div className='descriptionWrapper'>
                    {notificationTag}
                </div>
            </Col>
            <Col xs={12} md={6} className='actions'>
                <div className='options'>
                    {checkboxesDom}
                </div>
                {subOptions}
            </Col>
        </Row>;
    }, [tag, hasEditAccess, checkboxesDom, subscription, isUpdating, t]);

    if (!ready) {
        return;
    }

    return <div className='notificationPermission'>
        {permissionDom}
    </div>;
};

NotificationPermission.propTypes = {
    tag: PropTypes.object.isRequired,
    hasEditAccess: PropTypes.bool,
    subscription: PropTypes.array.isRequired,
    onOptionClick: PropTypes.func.isRequired,
    isUpdating: PropTypes.bool
};

export default NotificationPermission;
