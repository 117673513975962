export default (t) => {
    return [
        {
            label: t('Email'),
            checked: false, // add value from db here
            key: 'email'
        },
        {
            label: t('App'),
            checked: false, // add value from db here
            key: 'inApp'
        }
    ];
};
