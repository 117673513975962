import { Fragment, useMemo } from 'react';
import { Navbar, Nav, Accordion } from 'react-bootstrap';
import SidebarMenuItem from './SidebarMenuItem';
import PropTypes from 'prop-types';

import './SidebarMenu.scss';

const SidebarMenu = ({ items, locationPath }) => {

    const currentKey = useMemo(() => {
        if (!locationPath) {
            return '';
        }
        for (const item of items) {
            if (item.subMenu) {
                for (const element of item.subMenu) {
                    if (locationPath.endsWith(element.to)) {
                        return item.label;
                    }
                }
            }
            if (locationPath.endsWith(item.to)) {
                return item.label;
            }
        }

        return '';
    }, [locationPath, items]);

    const sidebarMenuItems = useMemo(() => items.map((item) => {
        if (item.subMenu) {
            const subMenu = item.subMenu.map((el) => {
                return <SidebarMenuItem
                    key={el.key || el.label}
                    label={el.label}
                    icon={el.icon}
                    url={el.url}
                    target={el.target}
                    to={el.to}
                    badgeLabel={el.badgeLabel}
                    locationPath={locationPath}
                />;
            });

            return (
                <Fragment key={item.key || item.label}>
                    <SidebarMenuItem
                        label={item.label}
                        icon={item.icon}
                        target={item.target}
                        to={item.subMenu[0]?.to}
                        badgeLabel={item.badgeLabel}
                        isMainMenuItem={true}
                        locationPath={locationPath}
                    />
                    <Accordion.Collapse eventKey={item.label}>
                        <Nav className='mr-auto submenu'>
                            <div className='sidebarMenuWrapper'>
                                {subMenu}
                            </div>
                        </Nav>
                    </Accordion.Collapse>
                </Fragment>
            );
        }

        return <SidebarMenuItem
            key={item.key || item.label}
            label={item.label}
            icon={item.icon}
            url={item.url}
            target={item.target}
            to={item.to}
            badgeLabel={item.badgeLabel}
            locationPath={locationPath}
        />;
    }), [items, locationPath]);

    return (
        <Navbar expand='lg'>
            <Navbar.Toggle aria-controls='sidebarMenu'>
                <i className='icon-angle-down'></i>
            </Navbar.Toggle>
            <Navbar.Collapse id='sidebarMenu'>
                <Nav className='mr-auto'>
                    <div className='sidebarMenuWrapper'>
                        <Accordion activeKey={currentKey} as='div'>
                            {sidebarMenuItems}
                        </Accordion>
                    </div>
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    );
};

SidebarMenu.propTypes = {
    items: PropTypes.array.isRequired,
    locationPath: PropTypes.string
};

export default SidebarMenu;
