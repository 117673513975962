import { NOTIFICATION_TYPES } from '@mdc/constants';
import { BackendService, IpRangeValidate, logService } from '@mdc/services';
import { ConfirmModal, NotificationContext, PlainTable, UserContext } from '@mdc/ui';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Button, ButtonGroup, Col, Form, FormControl, InputGroup, Row, Spinner } from 'react-bootstrap';
import ContentLoader from 'react-content-loader';
import { useTranslation } from 'react-i18next';
import './securityRules.scss';

const SecurityRules = () => {
    const userContext = useContext(UserContext);
    const { notify } = useContext(NotificationContext);

    const { t, ready } = useTranslation();
    const [showAdd, setShowAdd] = useState(false);
    const [inputIp, setInputIp] = useState('');
    const [ipList, setIpList] = useState();
    const [selectIpList, setSelectIpList] = useState([]);

    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [updatingIpList, setUpdatingIpList] = useState('');

    useEffect(() => {
        // Throttle requests
        let discard = false;
        const timeout = setTimeout(() => {
            // eslint-disable-next-line camelcase
            if (userContext.isOrganizationAdmin && userContext.isOrganizationEnterprise && userContext?.data?.sso_user_id && userContext?.data?.organization?.organization_id) {
                (async () => {
                    try {
                        // Get IP white list
                        const response = await BackendService.postGetOrganizationIpWhitelist({
                            userId: userContext.data.sso_user_id,
                            organizationId: userContext.data.organization.organization_id
                        })[0];

                        if (response.data && !discard) {
                            setIpList(response.data.ipWhitelist || []);
                        }
                    } catch (e) {
                        logService.error(e);
                        notify({
                            message: t('Request failed. Please try again!'),
                            type: NOTIFICATION_TYPES.CRITICAL
                        });
                    }
                })();
            }
        }, 1000);

        return function () {
            discard = true;
            clearTimeout(timeout);
        };
    }, [userContext]);

    const updateIpList = useCallback((list) => {
        setInputIp('');
        setShowAdd(false);
        setShowDeleteModal(false);

        (async () => {
            try {
                // update IP white list
                const response = await BackendService.postUpdateOrganizationIpWhitelist({
                    userId: userContext.data.sso_user_id,
                    organizationId: userContext.data.organization.organization_id,
                    ipWhitelist: list
                })[0];

                if (response.data) {
                    setIpList(response.data.ipWhitelist);
                    window && window.location.reload();
                }
            } catch (e) {
                logService.error(e);
                notify({
                    message: t('Request failed. Please try again!'),
                    type: NOTIFICATION_TYPES.CRITICAL
                });
            }
            setUpdatingIpList('');
            setSelectIpList([]);
        })();
    }, [userContext, inputIp, ipList]);

    const addIpRange = useCallback(() => {
        if (inputIp && ipList && IpRangeValidate.validate(inputIp)) {
            let list = ipList?.filter((ip) => ip !== inputIp);
            list = [...list, inputIp].filter((ip) => IpRangeValidate.validate(ip));

            setUpdatingIpList('add');
            updateIpList(list);
        }
    }, [updateIpList, ipList, inputIp]);

    const removeIpRange = useCallback(() => {
        if (ipList) {
            let list = ipList?.filter((ip) => !selectIpList.includes(ip));

            setUpdatingIpList('remove');
            updateIpList(list);
        }
    }, [updateIpList, ipList, selectIpList]);

    const modalDeleteIp = useMemo(() => {
        return <ConfirmModal
            className='advancedOptionsModal'
            show={showDeleteModal}
            onAccept={removeIpRange}
            onCancel={() => setShowDeleteModal(false)}
            title={t('Remove IP range')}
            body={<p>{t('Are you sure to remove these IP range?')}</p>}
            acceptText={t('Yes')}
            cancelText={t('No')}
        />;
    }, [showDeleteModal, removeIpRange]);

    const dom = useMemo(() => {
        const isOrganizationEnterprise = userContext.isOrganizationEnterprise;

        if (isOrganizationEnterprise === false) {
            return <div className='onlyEnterpriseText'>
                {t('This feature is available only on the enterprise licensing')}
            </div>;
        }

        if (!ipList) {
            const rows = 5;
            const space = 10;
            const rowHeight = 14;
            let keyCounter = 0;

            return <ContentLoader
                className={'contentLoader'}
                speed={1}
                height={rows * (14 + space)}
                width={'100%'}
                primaryColor='#EDEEF3'
                secondaryColor='#f3f3f3'>
                {Array(5).fill('').map((v, index) => <rect key={keyCounter++} x='0' y={index * (rowHeight + space)} rx='4' ry='4' width={`${Math.random() * 70 + 30}%`} height={rowHeight} />)}
            </ContentLoader>;
        }

        let tableBanner;
        if (updatingIpList) {
            tableBanner = <div className='loadingSpinner'>
                <Spinner animation='border' size={'sm'} />
            </div>;
        } else if (showAdd) {
            tableBanner = <>
                <InputGroup className='ipInputGroup'>
                    <FormControl
                        placeholder='x.x.x.x | x.x.x.x/xx | x:x:x:x:x:x:x:x | x:x:x:x:x:x:x:x/xxx'
                        aria-label='ip range'
                        onChange={(e) => setInputIp(e.target.value)}
                        onKeyPress={(e) => e.key === 'Enter' ? addIpRange() : undefined}
                        name='ip range'
                    />
                    <InputGroup.Append>
                        <Button disabled={!IpRangeValidate.validate(inputIp)} onClick={addIpRange}>
                            {t('Add')}
                        </Button>
                    </InputGroup.Append>
                </InputGroup>
                {inputIp && !IpRangeValidate.validate(inputIp) && <Form.Label htmlFor='invalid label'>{t('invalid IP range')}</Form.Label>}
            </>;
        } else if (!ipList.length) {
            tableBanner = <div className='noDataText'>
                {t('In order to restrict access to the API based on the IP of the users, please add a new IP range CIDR')}
            </div>;
        }

        return <PlainTable
            tableBanner={tableBanner}
            data={[...ipList].reverse().map((ip) => ({
                range: ip,
                action: <input name='securityRules' type='checkbox' disabled={!!updatingIpList} checked={selectIpList.includes(ip)} onClick={() => setSelectIpList((last) => last.includes(ip) ? last.filter((ip2) => ip2 !== ip) : [...last, ip])} />
            }))}
            columnsData={[
                {
                    Header: t('Allowed IP ranges'),
                    accessor: 'range',
                    hasSorting: true
                },
                {
                    Header: <ButtonGroup aria-label='Basic example'>
                        <Button className='addIpWhiteListBtn' disabled={!ipList || !!updatingIpList} onClick={() => setShowAdd((last) => !last)}>{t('Add IP Range')}</Button>
                        <Button className='removeIpWhiteListBtn' variant='danger' disabled={!ipList || !!updatingIpList || !selectIpList?.length} onClick={() => setShowDeleteModal(true)}>{t('Remove')}</Button>
                    </ButtonGroup>,
                    accessor: 'action'
                }
            ]} />;
    }, [t, userContext, showAdd, inputIp, ipList, addIpRange, updatingIpList, selectIpList]);

    if (!ready || !userContext) {
        return null;
    }

    return <div className='securityRules'>
        <h2>{t('IP Allowlist')}</h2>
        <Row>
            <Col className='domCol'>
                {dom}
            </Col>
        </Row>
        {modalDeleteIp}
    </div>;
};



export default SecurityRules;
