/* eslint-disable camelcase */
import { useContext, useMemo, useState, useRef, useEffect } from 'react';
import { NotificationContext, InternalLink } from '@mdc/ui';
import { Button, Col, Row, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { BackendService } from '@mdc/services';
import { NOTIFICATION_TYPES } from '@mdc/constants';
import PropTypes from 'prop-types';


import './SecuritySalesForce.scss';


const SecuritySalesforce = ({ user }) => {
    const { t } = useTranslation();
    const { notify } = useContext(NotificationContext);
    const [isEditing, setIsEditing] = useState(false);
    const [editingField, setEditingField] = useState('');
    const [csfsData, setCsfsData] = useState({
        userName: user?.data?.csfs?.userName,
        loginUrl: user?.data?.csfs?.loginUrl,
        clientKey: user?.data?.csfs?.clientKey,
        privateKey: user?.data?.csfs?.privateKey ? '*****' : ''
    });

    const refTextareaUserName = useRef(null);
    const refTextareaLoginUrl = useRef(null);
    const refTextareaClientKey = useRef(null);
    const refTextareaPrivateKey = useRef(null);

    const handleEditClick = (field) => () => {
        setEditingField(field);
        setIsEditing(true);
    };

    const UpdateCsfsData = async (csfsData) => {
        if (user?.isPaidUser) {
            try {
                const [updateApiKey] = BackendService.updateApiKey({ csfs: csfsData });
                let response = await updateApiKey;
                return !!response?.data;
            } catch (error) {
                return false;
            }
        }
    };

    useEffect(() => {
        if (!isEditing) {
            return;
        }
        (async () => {
            if (await UpdateCsfsData(csfsData)) {
                notify({
                    message: t('Your CSFS data was updated!'),
                    type: NOTIFICATION_TYPES.SUCCESS
                });
                setIsEditing(false);
            } else {
                notify({
                    message: t('Request failed. Please try again!'),
                    type: NOTIFICATION_TYPES.CRITICAL
                });
            }
        })();
    }, [csfsData]);


    const handleUpdate = () => {
        const updatedData = {
            ...csfsData,
            userName: refTextareaUserName?.current?.value,
            loginUrl: refTextareaLoginUrl?.current?.value,
            clientKey: refTextareaClientKey?.current?.value,
            privateKey: refTextareaPrivateKey?.current?.value
        };

        if (!updatedData.privateKey) {
            updatedData.privateKey = undefined;
        }

        if (!updatedData.userName) {
            updatedData.userName = undefined;
        }

        if (!updatedData.loginUrl) {
            updatedData.loginUrl = undefined;
        }

        if (!updatedData.clientKey) {
            updatedData.clientKey = undefined;
        }

        setCsfsData(updatedData);
    };

    const handleCancel = (evt) => {
        evt.preventDefault();
        setIsEditing(false);
    };

    const dom = useMemo(() => {
        return (
            <div className='mt-2 csfs'>
                <Row className='mb-2 value-row'>
                    <Col lg={2} md={3} sm={4} className='value-label'>
                        {t('UserName')}:
                    </Col>
                    <Col lg={10} md={9} sm={8} xs={12} className='value-text'>
                        {isEditing ? (
                            <Form.Control as='textarea' rows={1} ref={refTextareaUserName} className='small-input' defaultValue={csfsData.userName} />
                        ) : (
                            <div className='text-area-padding'>{csfsData?.userName || 'Not set'}</div>
                        )}
                    </Col>
                </Row>
                <Row className='mb-2 value-row'>
                    <Col lg={2} md={3} sm={4} className='value-label'>
                        {t('Login Url')}:
                    </Col>
                    <Col lg={10} md={9} sm={8} xs={12} className='value-text'>
                        {isEditing ? (
                            <Form.Control as='textarea' rows={1} className='small-input' ref={refTextareaLoginUrl} defaultValue={csfsData.loginUrl} />
                        ) : (
                            <div className='text-area-padding'>{csfsData?.loginUrl || 'Not set'}</div>
                        )}
                    </Col>
                </Row>
                <Row className='mb-2 value-row'>
                    <Col lg={2} md={3} sm={4} className='value-label'>
                        {t('ClientKey')}:
                    </Col>
                    <Col lg={10} md={9} sm={8} xs={12} className='value-text'>
                        {isEditing ? (
                            <Form.Control as='textarea' rows={1} className='small-input' ref={refTextareaClientKey} defaultValue={csfsData.clientKey} />
                        ) : (
                            <div className='text-area-padding'>{csfsData?.clientKey || 'Not set'}</div>
                        )}
                    </Col>
                </Row>
                <Row className='mb-2 value-row'>
                    <Col lg={2} md={3} sm={4} className='value-label'>
                        {t('PrivateKey')}:
                    </Col>
                    <Col lg={10} md={9} sm={8} xs={12} className='value-text'>
                        {isEditing ? (
                            <Form.Control as='textarea' rows={3} ref={refTextareaPrivateKey} defaultValue={csfsData.privateKey ? '*****' : ''} />
                        ) : (
                            <div className='text-area-padding'>{csfsData?.privateKey ? '*****' : 'Not set'}</div>
                        )}
                    </Col>
                </Row>
                <Row className='mb-2 value-row'>
                    <Col lg={2} md={3} sm={4} />
                    <Col lg={10} md={9} sm={8} xs={12} className='value-text'>
                        <div className='rsaKeyButton mt-2'>
                            {isEditing ? (
                                <div className='d-flex flex-row align-items-center'>
                                    <Button variant='primary' type='submit' className='updateRsaKeyButton' onClick={handleUpdate}>
                                        {t('Save')}
                                    </Button>
                                    <InternalLink onClick={handleCancel} to='/' className='ml-3'>{t('Cancel')}</InternalLink>
                                </div>
                            ) : (
                                <Button variant='primary' onClick={handleEditClick()}>
                                    {t('Edit secrets')}
                                </Button>
                            )}
                        </div>
                    </Col>
                </Row>
            </div>
        );
    }, [t, editingField, csfsData, isEditing]);

    return <div className='accountDeveloper secrets'>
        <h1>{t('Cloud Security for Salesforce')}</h1>
        {dom}
    </div>;
};

SecuritySalesforce.propTypes = {
    user: PropTypes.object.isRequired,
};

export default SecuritySalesforce;
