import { useMemo } from 'react';
import PropTypes from 'prop-types';
import SidebarMenu from '../../sidebar-menu/SidebarMenu';
import { Row, Col } from 'react-bootstrap';
import classnames from 'classnames';
import { useLocation } from '@reach/router';

import './SidebarPageLayout.scss';

const SidebarPageLayout = ({ pageTitle, menuItems, pageName, children }) => {
    const sideBarPageContentClasses = classnames('sidebarPageContent', pageName);
    const location = useLocation();
    const path = useMemo(() => location?.pathname, [location]);

    return (
        <Row className='sidebarLayout'>
            {pageTitle &&
            <Col xs={12}>
                <h1>{pageTitle}</h1>
            </Col>
            }
            <Col xs={12} lg={3} className='sidebarPageMenu'>
                <SidebarMenu items={menuItems} locationPath={path}/>
            </Col>
            <Col xs={12} lg={9} className={sideBarPageContentClasses}>
                {children}
            </Col>
        </Row>
    );
};

SidebarPageLayout.propTypes = {
    pageTitle: PropTypes.string,
    menuItems: PropTypes.array.isRequired,
    children: PropTypes.element,
    pageName: PropTypes.string.isRequired
};

export default SidebarPageLayout;
