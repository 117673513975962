import { useContext, useMemo, useRef, useState } from 'react';
import { IconButton, UserContext } from '@mdc/ui';
import PropTypes from 'prop-types';
import { Col, Overlay, Row, Tooltip } from 'react-bootstrap';
import Clipboard from 'react-clipboard.js';
import { useTranslation } from 'react-i18next';
import OAuth2Client from '../account/developer/OAuth2Client';
import SecurityRules from '../account/organization/security-rules/securityRules';
import RsaKeys from '../account/rsa-keys/RsaKeys';
import SecuritySalesforce from '../account/security-salesforce/SecuritySalesforce';

import './Security.scss';

const Security = ({ user }) => {
    const userContext = useContext(UserContext);
    const isPaidUser = userContext.isPaidUser;
    const isOrganizationAdmin = userContext.isOrganizationAdmin;
    const [show, setShow] = useState(false);
    const { t } = useTranslation();
    const target = useRef(null);
    const tooltipDOM = (tooltipProps) => {
        tooltipProps.show = tooltipProps.show.toString();
        return (
            <Tooltip id='tooltip-bottom' {...tooltipProps}>
                {t('Copied!')}
            </Tooltip>
        );
    };
    const apikeyDom = useMemo(() => {
        if (!userContext) {
            return;
        }

        return (
            <Row>
                <Col lg={5} md={4} sm={4}>
                    <h2>Api Key</h2>
                </Col>
                <Col lg={8} md={9} sm={9} className='valueCol'>
                    <span className='valuesText'>{userContext?.data?.apikey}</span>
                    <Clipboard
                        className='iconButton'
                        component='span'
                        data-clipboard-text={userContext?.data?.apikey}
                    >
                        <IconButton
                            iconClass='icon-clone'
                            onClick={() => setShow(!show)}
                            ref={target}
                        />
                        <Overlay
                            target={target.current}
                            show={show}
                            rootClose={true}
                            onHide={() => setShow(false)}
                            placement='bottom-end'
                        >
                            {tooltipDOM}
                        </Overlay>
                    </Clipboard>
                </Col>
            </Row>
        );
    }, [userContext, show, tooltipDOM, target, t]);

    return (
        <section className='security-container'>
            <h1>{t('Auth')}</h1>
            {apikeyDom}
            <div className='spaceline'>{isPaidUser && <OAuth2Client />}</div>
            {isOrganizationAdmin && <SecurityRules />}
            {isPaidUser && (
                <>
                    <Row className='separator'>
                        <div></div>
                    </Row>
                    <RsaKeys user={user} />

                    <Row className='separator'>
                        <div></div>
                    </Row>
                    <SecuritySalesforce user={user} />
                </>
            )
            }
        </section >
    );
};
Security.propTypes = {
    user: PropTypes.object.isRequired,
};
export default Security;
