/* eslint-disable camelcase */
import { useMemo } from 'react';
import { Col, Row, ProgressBar } from 'react-bootstrap';
import { StatisticsCard, account, PieChart, StackedBar } from '@mdc/ui';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { COLORS } from '../dashboard/Colors';
import ContentLoader from 'react-content-loader';
import { ShortNumber } from '@mdc/services';

const { StatisticsFileType } = account;

import './Statistics.scss';

const SERVICE_MAP = (t) => ({
    fileScan: {
        pies: [
            {
                title: t('Allowed / Blocked'),
                scoreText: t('Files processed'),
                pieces: [
                    {
                        title: t('Allowed'),
                        color: COLORS.areaColor,
                        value: (data) => data?.statistics?.file_scan?.allowed_files || 0
                    },
                    {
                        title: t('Blocked'),
                        color: COLORS.suspiciousColor,
                        value: (data) => data?.statistics?.file_scan?.blocked_files || 0
                    }
                ]
            },
            {
                title: t('Blocked'),
                scoreText: t('Files processed'),
                pieces: [
                    {
                        title: t('Infected'),
                        color: COLORS.infectedColor,
                        value: (data) => data?.statistics?.file_scan?.infected_files || 0
                    },
                    {
                        title: t('Other'),
                        color: COLORS.suspiciousColor,
                        value: (data) => (data?.statistics?.file_scan?.blocked_files - data?.statistics?.file_scan?.infected_files) || 0
                    },
                ]
            }
        ],
        bars: [
            {
                title: t('Vulnerabilities'),
                scoreText: t('Vulnerabilities'),
                rightTitle: (data) => (data?.statistics?.file_scan?.vulnerabilities?.total_critical || 0) + (data?.statistics?.file_scan?.vulnerabilities?.total_important || 0) + (data?.statistics?.file_scan?.vulnerabilities?.total_moderate || 0) + (data?.statistics?.file_scan?.vulnerabilities?.total_low || 0) + (data?.statistics?.file_scan?.vulnerabilities?.total_unknown || 0),
                pieces: [
                    { title: t('Critical'), icon: 'icon-bars1', value: (data) => data?.statistics?.file_scan?.vulnerabilities?.total_critical || 0 },
                    { title: t('Important'), icon: 'icon-bars2', value: (data) => data?.statistics?.file_scan?.vulnerabilities?.total_important || 0 },
                    { title: t('Moderate'), icon: 'icon-bars3', value: (data) => data?.statistics?.file_scan?.vulnerabilities?.total_moderate || 0 },
                    { title: t('Low'), icon: 'icon-bars4', value: (data) => data?.statistics?.file_scan?.vulnerabilities?.total_low || 0 },
                    { title: t('Unknown'), icon: 'icon-bars5', value: (data) => data?.statistics?.file_scan?.vulnerabilities?.total_unknown || 0 }
                ]
            },
            {
                title: t('Deep CDR'),
                scoreText: t('Files processed'),
                rightTitle: (data) => (data?.statistics?.file_scan?.deep_cdr?.total_sanitized_ok || 0) + (data?.statistics?.file_scan?.deep_cdr?.total_sanitized_failed || 0) + (data?.statistics?.file_scan?.deep_cdr?.total_sanitized_partially || 0) + (data?.statistics?.file_scan?.deep_cdr?.total_sanitized_timeout || 0),
                pieces: [
                    { title: t('OK'), icon: 'icon-Successful', color: COLORS.cleanColor, value: (data) => data?.statistics?.file_scan?.deep_cdr?.total_sanitized_ok || 0 },
                    { title: t('Failed'), icon: 'icon-Failed', color: COLORS.suspiciousColor, value: (data) => data?.statistics?.file_scan?.deep_cdr?.total_sanitized_failed || 0 },
                ]
            }
        ],
        topFiles: {
            total: (data) => data?.statistics?.file_scan?.scanned_files || 0,
            charts: [
                {
                    title: t('Scanned'),
                    icon: 'icon-doc-text',
                    tops: (data) => data?.statistics?.file_scan?.filetypes_scanned || []
                },
                {
                    title: t('Allowed'),
                    icon: 'icon-file-allowed',
                    tops: (data) => data?.statistics?.file_scan?.filetypes_allowed || []
                },
                {
                    title: t('Blocked'),
                    icon: 'icon-file-blocked',
                    tops: (data) => data?.statistics?.file_scan?.filetypes_blocked || []
                },
                {
                    title: t('Infected'),
                    icon: 'icon-bug',
                    tops: (data) => data?.statistics?.file_scan?.filetypes_infected || []
                }
            ]
        }
    },
    sandbox: {
        pies: [
            {
                title: t('Scan results'),
                scoreText: t('Files processed'),
                pieces: [
                    {
                        title: t('No Threat Detected'),
                        color: COLORS.areaColor,
                        value: (data) => data?.statistics?.sandbox?.total_clean || 0
                    },
                    {
                        title: t('Infected'),
                        color: COLORS.infectedColor,
                        value: (data) => data?.statistics?.sandbox?.total_infected || 0
                    },
                    {
                        title: t('Suspicious'),
                        color: COLORS.suspiciousColor,
                        value: (data) => data?.statistics?.sandbox?.total_suspicious || 0
                    }
                ]
            }
        ],
        topFiles: {
            total: (data) => data?.statistics?.sandbox?.total || 0,
            charts: [
                {
                    title: t('Scanned'),
                    icon: 'icon-doc-text',
                    tops: (data) => data?.statistics?.sandbox?.filetypes_scanned || []
                },
                {
                    title: t('Allowed'),
                    icon: 'icon-file-allowed',
                    tops: (data) => data?.statistics?.sandbox?.filetypes_clean || []
                },
                {
                    title: t('Suspicious'),
                    icon: 'icon-warning-empty',
                    tops: (data) => data?.statistics?.sandbox?.filetypes_suspicious || []
                },
                {
                    title: t('Infected'),
                    icon: 'icon-bug',
                    tops: (data) => data?.statistics?.sandbox?.filetypes_infected || []
                }
            ]
        }
    },
    fileReputation: {
        pies: [
            {
                title: t('Hit ratio'),
                scoreText: t('File processed'),
                pieces: [
                    {
                        title: t('Found'),
                        color: COLORS.foundColor,
                        value: (data) => data?.statistics?.file_reputation?.found || 0
                    },
                    {
                        title: t('Not found'),
                        color: COLORS.notFoundColor,
                        value: (data) => data?.statistics?.file_reputation?.missing || 0
                    }
                ]
            },
            {
                title: t('No Threat - Infected ratio'),
                scoreText: t('File processed'),
                pieces: [
                    {
                        title: t('No Threat Detected'),
                        color: COLORS.cleanColor,
                        value: (data) => data?.statistics?.file_reputation?.clean || 0
                    },
                    {
                        title: t('Infected'),
                        color: COLORS.infectedColor,
                        value: (data) => data?.statistics?.file_reputation?.infected || 0
                    }
                ]
            }
        ]
    },
    address: {
        pies: [
            {
                title: t('IP, URL, Domain reputation'),
                scoreText: ('Total processed'),
                size: 5,
                pieces: [
                    {
                        title: t('Trustworthy'),
                        color: COLORS.cleanColor,
                        value: (data) => (data?.statistics?.ip_reputation?.clean || 0) + (data?.statistics?.url_reputation?.clean || 0) + (data?.statistics?.domain_reputation?.clean || 0)
                    },
                    {
                        title: t('Malicious'),
                        color: COLORS.infectedColor,
                        value: (data) => (data?.statistics?.ip_reputation?.infected || 0) + (data?.statistics?.url_reputation?.infected || 0) + (data?.statistics?.domain_reputation?.infected || 0)
                    },
                    {
                        title: t('Not found'),
                        color: COLORS.notFoundColor,
                        value: (data) => (data?.statistics?.ip_reputation?.missing || 0) + (data?.statistics?.url_reputation?.missing || 0) + (data?.statistics?.domain_reputation?.missing || 0)
                    }
                ]
            }
        ],
        stacked: [{
            size: 7,
            title: t('Endpoint'),
            categories: [
                {
                    title: t('IP'),
                    access: (data) => data?.statistics?.ip_reputation
                },
                {
                    title: t('URL'),
                    access: (data) => data?.statistics?.url_reputation
                },
                {
                    title: t('Domain'),
                    access: (data) => data?.statistics?.domain_reputation
                },
            ],
            values: [
                {
                    title: t('Trustworthy'),
                    color: COLORS.cleanColor,
                    access: (data) => data?.clean || 0
                },
                {
                    title: t('Malicious'),
                    color: COLORS.infectedColor,
                    access: (data) => data?.infected || 0
                },
                {
                    title: t('Not found'),
                    color: COLORS.notFoundColor,
                    access: (data) => data?.missing || 0
                }
            ]
        }]
    }
});

const Statistics = ({ data, service }) => {
    const { t, ready } = useTranslation();
    const serviceMapped = useMemo(() => SERVICE_MAP(t)[service.key] || SERVICE_MAP(t).fileScan, [service, t]);

    const chartDom = useMemo(() => {
        return <>
            {serviceMapped.pies?.map((pie) => {
                const cardContentDom = <div className='cardContent'>
                    <Col lg={5} xs={6} className='d-flex align-items-center flex-row pl-0 pr-0'>
                        <PieChart data={data ? pie.pieces.map((p) => ({ ...p, value: p.value(data) })) : undefined} />
                    </Col>
                    <Col lg={7} xs={6}>
                        {pie?.pieces?.map((item) => {
                            if (!data) {
                                return <Row key={item.id}>
                                    <ContentLoader
                                        className='chartContentLoader'
                                        height={25}
                                        width={'100%'}
                                        viewBox={'0 0 100 25'}
                                    >
                                        <rect x='0' y='0' rx='4' ry='4' width={`${60 + 40 * Math.random()}%`} height='12' />
                                    </ContentLoader>
                                </Row>;
                            }

                            const valueData = item.value(data);
                            return <Row key={item.id}>
                                <div className='metascanLabel'>{item.title}
                                    <div className='metascanValue' title={valueData}>{ShortNumber.short(valueData)}</div>
                                </div>
                            </Row>;
                        })}
                    </Col>
                </div>;

                return <Col xs={12} xl={6} className='px-0' key={pie.id}>
                    <StatisticsCard
                        title={pie.title}
                        scoreText={pie.scoreText}
                        score={pie?.pieces?.reduce((total, current) => total + (current?.value(data) || 0), 0)}
                        content={cardContentDom}
                    />
                </Col>;
            })}

            {serviceMapped.bars?.map((bar) => {
                const pieces = bar.pieces.map((item) => ({
                    ...item,
                    value: item.value(data)
                }));
                const max = Math.max(...pieces.map((item) => item.value));

                let dom;
                if (!data) {
                    dom = bar.pieces.map((item) => <Row className='contentRow' key={item.id}>
                        <ContentLoader
                            className='chartContentLoader'
                            height={12}
                            width={'100%'}
                            viewBox={'0 0 100 25'}
                        >
                            <rect x='0' y='0' rx='4' ry='4' width={`${70 + 30 * Math.random()}%`} height='12' />
                        </ContentLoader>
                    </Row>);
                } else {
                    dom = <Row className='contentRow'>
                        <div className='contentCol'>
                            {pieces.map((item) => <span key={item.id} className='iconText'><i style={{ backgroundColor: item.color }} className={item.icon} />{item.title}</span>)}
                        </div>
                        <div className='contentCol contentProgress'>
                            {pieces.map((item) => <div key={item.id}><ProgressBar now={item.value} max={max} /></div>)}
                        </div>
                        <div className='contentCol'>
                            {pieces.map((item) => <div key={item.id} title={item.value}>{ShortNumber.short(item.value)}</div>)}
                        </div>
                    </Row>;
                }

                const cardContentDom = <div className='cardContent' key={bar.id}>
                    <Col xs={12}>
                        {dom}
                    </Col>
                </div>;

                return <Col xs={12} xl={6} className='px-0' key={bar.id}>
                    <StatisticsCard
                        title={bar.title}
                        scoreText={bar.scoreText}
                        score={bar.rightTitle(data)}
                        content={cardContentDom}
                    />
                </Col>;
            })}

            {serviceMapped.stacked?.map((stack) => {
                const cardContentDom = <div className='cardContent'>
                    <StackedBar
                        categories={stack.categories.map((category) => category.title)}
                        series={data ? stack.values.map((value) => ({
                            name: value.title,
                            data: stack.categories.map((category) => value.access(category.access(data)))
                        })) : undefined}
                        colors={stack.values.map((value) => value.color)}
                    />
                </div>;

                return <Col xs={12} xl={6} className='px-0' key={stack.id}>
                    <StatisticsCard
                        title={stack.title}
                        scoreText={''}
                        score={0}
                        content={cardContentDom}
                    />
                </Col>;
            })}
        </>;
    }, [serviceMapped, data]);

    const fileTypeDom = useMemo(() => {
        if (!serviceMapped.topFiles) {
            return;
        }

        const dom = serviceMapped.topFiles?.charts?.map((top) => {
            return <StatisticsFileType key={top.id} icon={top.icon} title={top.title} data={data ? top.tops(data).slice(0, 5) : undefined} />;
        });

        return <Row className='fileTypeRow mt-md-4 mb-5 ml-0 mr-0'>
            <Col xs={12} className='fileTypeRowTitle pl-0 pr-0'>
                <strong>{t('File Type')}</strong>
            </Col>
            {dom}
        </Row>;
    }, [serviceMapped, data]);

    if (!ready) {
        return;
    }

    return <div className='statisticsReports'>
        <Row>
            {chartDom}
        </Row>
        {fileTypeDom}
    </div>;
};

Statistics.propTypes = {
    data: PropTypes.object,
    service: PropTypes.object
};


export default Statistics;
