/* eslint-disable camelcase */
// eslint-disable-next-line no-unused-vars
import React, { useEffect, useState, useRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import FiltersData from './FiltersData';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'react-bootstrap';
import { account, FeedbackPopup, InternalLink } from '@mdc/ui';
import Dashboard from '../../account/reports/dashboard/Dashboard';
import Statistics from '../../account/reports/statistics/Statistics';
import DateTime from 'react-datetime';
import { logService, BackendService, dayjs, localStorage } from '@mdc/services';

import './AccountReportsLayout.scss';
import 'react-datetime/css/react-datetime.css';

const { Filter, AccountBanner } = account;
const PAGE_VISITED = 'statisticsVisited';
const LIMITATIONS_START_DATE = '2021-09-03';

const AccountReportsLayout = (props) => {
    const { pageName, user, isOrganizationAdmin } = props;
    const diffBetweenNowAndSepDate = Math.floor(dayjs.duration(dayjs().diff(dayjs(LIMITATIONS_START_DATE))).asHours());

    const { t, ready } = useTranslation();
    const { SERVICES, CUSTOM_KEY, DEFAULT_RANGE_MODE, DASHBOARD_DROPDOWN, STATISTICS_DROPDOWN } = FiltersData(t);
    const [range, setRange] = useState(DEFAULT_RANGE_MODE[0]);
    const [dataType, setDataType] = useState(() => {
        if (!pageName || !['dashboard', 'statistics'].includes(pageName)) {
            return null;
        }

        if (pageName === 'dashboard') {
            return DASHBOARD_DROPDOWN;
        }

        return STATISTICS_DROPDOWN;
    });
    const [selectedDataType, setSelectedDataType] = useState(() => {
        if (dataType) {
            return user?.organization?.organization_id && isOrganizationAdmin ? dataType[0] : dataType[1];
        }

        return null;
    });
    const [startFrom, setStartFrom] = useState(dayjs());
    const [service, setService] = useState(SERVICES[0]);
    const [isCustomDateVisible, setIsCustomDateVisible] = useState(false);
    const [isLimitationsBannerVisible, setIsLimitationBannerVisible] = useState(range?.hours > diffBetweenNowAndSepDate);

    const [dashboardData, setDashboardData] = useState(undefined);
    const [statisticsData, setStatisticsData] = useState(undefined);
    const [pageVisited, setPageVisited] = useState(false);
    const [hoursRange, setHoursRange] = useState(range);
    const [isEligible, setIsEligible] = useState(false);
    const [close, setClose] = useState(false);
    const popupTriggerRef = useRef(null);

    const getReportsData = {
        ...((selectedDataType?.key === 'personal' || !user?.organization?.organization_id) && { customerId: user.sso_user_id }),
        ...(selectedDataType?.key === 'organization' && user?.organization?.organization_id && { customerId: user.sso_user_id, organizationId: user?.organization?.organization_id || null }),
        hours: typeof hoursRange === 'number' ? hoursRange : hoursRange?.hours,
        report_scope: selectedDataType?.key || 'organization'
    };

    const handleServiceUpdate = (item) => {
        setService(item);
    };

    const handleRangeUpdate = (item) => {
        if (item.key === 'custom') {
            setIsCustomDateVisible(true);
        } else {
            setIsCustomDateVisible(false);
        }

        setRange(item);
    };

    const handleTypeUpdate = (item) => {
        setSelectedDataType((last) => {
            if (last !== item) {
                // If there is hour range change, remove all current data before load new
                setDashboardData(undefined);
                setStatisticsData(undefined);
                return item;
            }
            return last;
        });
    };

    useEffect(() => {
        if (!['dashboard', 'statistics'].includes(pageName)) {
            return;
        }

        if (pageName === 'dashboard') {
            setDataType(DASHBOARD_DROPDOWN);
        } else {
            setDataType(STATISTICS_DROPDOWN);
        }
    }, [pageName]);

    useEffect(() => {
        setSelectedDataType((last) => {
            // Preserve `organization` or `customer` even when the page name changes
            const prevSelectionType = dataType.filter((item) => item.key === last.key);
            return prevSelectionType[0];
        });
    }, [dataType]);

    useEffect(() => {
        // Throttle range select
        let timeout = setTimeout(() => {
            setHoursRange((last) => {
                if (last !== range) {
                    // If there is hour range change, remove all current data before load new
                    setDashboardData(undefined);
                    setStatisticsData(undefined);

                    // Update limitations banner visibility based on range update
                    setIsLimitationBannerVisible(range?.hours > diffBetweenNowAndSepDate);
                    return range;
                }
                return last;
            });
        }, 1000);

        return function () {
            clearTimeout(timeout);
        };
    }, [range]);

    useEffect(() => {
        let discard = false;

        if (!discard) {
            handleRefresh(getReportsData);
        }

        return () => {
            // Discard current load data
            discard = true;
        };
    }, [hoursRange, pageName, dashboardData, getReportsData, statisticsData, user, selectedDataType, dataType]);

    useEffect(() => {
        (async () => {
            try {
                const [promise] = BackendService.getFeedback(user.sso_user_id, pageName);
                const response = await promise;
                if (response.data) {
                    setIsEligible(response.data.eligible);
                    setClose(false);
                }
            } catch (e) {
                logService.error(e);
            }
        })();

        if (pageName === 'statistics' || pageName === 'dashboard') {
            const pageVisitedCount = localStorage.getItem(PAGE_VISITED) || 0;
            localStorage.setItem(PAGE_VISITED, pageVisitedCount + 1);
        }

        if (localStorage.getItem(PAGE_VISITED) > 2) {
            setPageVisited(true);
        }

        if (range.key === CUSTOM_KEY) {
            setRange({
                label: 'Custom',
                key: CUSTOM_KEY,
                hours: Math.floor(dayjs.duration(dayjs().diff(startFrom)).asHours())
            });
        }
    }, [startFrom, pageName, t]);

    useEffect(() => {
        if ((!user?.organization?.organization_id || !isOrganizationAdmin) && dataType) {
            setSelectedDataType(dataType[1]);
        }
    }, [user, isOrganizationAdmin]);

    const customDateInput = useMemo(() => {
        if (!isCustomDateVisible) {
            return;
        }

        return (
            <DateTime
                dateFormat={`[${t('From')}:] YYYY-MM-DD`}
                timeFormat={'HH:[00]'}
                value={startFrom}
                onChange={(value) => setStartFrom(value)}
                isValidDate={(date) => {
                    return dayjs().diff(dayjs(date)) > 0;
                }}
            />
        );
    }, [isCustomDateVisible, startFrom, t]);



    const handleRefresh = (getReportsData) => {
        const lastData = pageName === 'dashboard' ? dashboardData : statisticsData;
        const setAction = pageName === 'dashboard' ? setDashboardData : setStatisticsData;

        // Reload data if range change and there is no data
        if (hoursRange !== undefined && ['dashboard', 'statistics'].includes(pageName) && !lastData && user) {

            (async () => {
                try {
                    const [promise] = BackendService.postReports(getReportsData, pageName);
                    const response = await promise;
                    // Check if there is data and not discard for other loading data
                    if (response.data) {
                        setAction({ data: response.data });
                    }
                } catch (e) {
                    logService.error(e);
                }
            })();
        }
    };

    const getResponse = (response) => {

        const POST_FEEDBACK = {
            userId: user.sso_user_id,
            email: response.isAnonymous ? '' : user.email,
            score: response.score,
            message: response.message,
            optOut: response.optOut,
            page: pageName
        };

        (async () => {
            try {
                const [promise] = BackendService.postFeedback(POST_FEEDBACK, pageName);
                const feedback = await promise;
                // Check if there is data and not discard for other loading data
                if (feedback.data) {
                    setClose(true);
                }
            } catch (e) {
                logService.error(e);
            }
        })();
    };

    const bodyDOM = useMemo(() => {
        switch (pageName) {
            case 'dashboard':
                return <Dashboard data={dashboardData?.data} service={service} />;
            case 'statistics':
                return <Statistics data={statisticsData?.data} service={service} />;
            default:
                return null;
        }
    }, [dashboardData, statisticsData, pageName, service, selectedDataType]);

    const pageTitle = useMemo(() => {
        switch (pageName) {
            case 'dashboard':
                return t('Dashboard');
            case 'statistics':
                return t('Statistics');
            default:
                return null;
        }
    }, [pageName, t]);

    const handleCloseBanner = () => {
        setClose(true);
    };

    const feedbackBanner = useMemo(() => {
        if (close || !isEligible || !pageVisited) {
            return;
        }

        const bannerDom = (
            <Row className='banner-text'>
                <Col className='first-sentence'>{t('Let us know how we can improve your experience.')}</Col>
                <Col className='second-sentence'>{t('Please feel free to provide us feedback or suggest new features you would like to see here.')}
                    <a className='call-to-action' ref={popupTriggerRef}>{t('Give Feedback')}</a>
                    <FeedbackPopup sendFeedBackResponse={getResponse} triggerRef={popupTriggerRef} />
                </Col>
            </Row>
        );

        return <AccountBanner contentDom={bannerDom} handleCloseBanner={handleCloseBanner} />;
    }, [close, isEligible, pageVisited, t]);

    const limitsBanner = useMemo(() => {
        if (!isLimitationsBannerVisible) {
            return;
        }

        const bannerDom = (
            <Row className='banner-text'>
                <Col className='first-sentence'>{t('File submissions done prior to September 3, 2021 will not be visible on the dashboards and statistics pages.')}</Col>
                <Col className='second-sentence'>
                    {t('However, you can still view those submissions on the')}
                    {' '}
                    <InternalLink to='/account/submission-history'>{t('submission history')}</InternalLink>
                    {' '}
                    {t('page.')}
                </Col>
            </Row>
        );

        return <AccountBanner contentDom={bannerDom} />;
    }, [isLimitationsBannerVisible, t]);

    const reportTypeFilterDom = useMemo(() => {
        if (!user || !user?.organization?.organization_id || !isOrganizationAdmin) {
            return;
        }

        return <Filter key={selectedDataType?.label} items={dataType} selectedItem={selectedDataType} handleUpdate={handleTypeUpdate} alignRight={true} />;
    }, [user, selectedDataType, dataType, handleTypeUpdate, isOrganizationAdmin]);

    if (!ready) {
        return;
    }

    return <div className='accountReports'>
        <Row>
            <Col xs={6}>
                <h1>{pageTitle}</h1>
            </Col>
            <Col xs={6} className='text-right'>
                {reportTypeFilterDom}
                <FeedbackPopup sendFeedBackResonse={getResponse} />
            </Col>
        </Row>
        {feedbackBanner}
        <Row className='mb-4'>
            <Col xs={12} className='d-flex w-100 justify-content-between align-items-center'>
                <Row className='m-0'>
                    <Col xs={12} className='p-0'>
                        <Filter items={SERVICES} selectedItem={service} handleUpdate={handleServiceUpdate} icon={'icon-share'} />
                        <Filter items={DEFAULT_RANGE_MODE} selectedItem={range} handleUpdate={handleRangeUpdate} icon={'icon-calendar-empty'} />
                        {customDateInput}
                    </Col>
                </Row>
            </Col>
        </Row>
        {limitsBanner}
        <Row>
            <Col>
                {bodyDOM}
            </Col>
        </Row>
    </div>;
};

AccountReportsLayout.propTypes = {
    pageName: PropTypes.string.isRequired,
    user: PropTypes.object,
    isOrganizationAdmin: PropTypes.bool
};

export default AccountReportsLayout;
