/* eslint-disable camelcase */
import { useContext, useEffect, useMemo, useState } from 'react';
import { COOKIE_OPSWAT_REDIRECT } from '@mdc/constants';
import { AnnouncementBanner, UserContext } from '@mdc/ui';
import AccountInformation from '../../components/account/account-information/AccountInformation';
import NotificationSettings from '../../components/account/settings/notifications/NotificationSettings';
import SubmissionHistory from '../../components/account/submission-history/SubmissionHistory';
import VulnerabilitySubmissions from '../../components/account/vulnerability-submissions/VulnerabilitySubmissions';
import AccountReportsLayout from '../../components/layouts/account-reports/AccountReportsLayout';
import Layout from '../../components/layouts/freshest/FreshestLayout';
import Security from '../../components/security/Security';
import SideBarPageLayout from '../../components/layouts/sidebar-page/SidebarPageLayout';
import Cookie from 'js-cookie';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import './AccountPage.scss';

const AccountPage = (props) => {
    const [windowLocation, setWindowLocation] = useState({ href: '/' });
    const [user, setUser] = useState();
    const [loggedIn, setLoggedIn] = useState(false);
    const { t, ready } = useTranslation();
    const { pageContext, params } = props;
    const currentPage = pageContext.currentPage;
    const userContext = useContext(UserContext);
    const authIsLoading = userContext.state === userContext.STATES.PENDING;

    const menuItems = useMemo(
        () => [
            {
                label: t('Account information'),
                icon: 'icon-star',
                to: '/account',
            },
            {
                label: t('Dashboard'),
                icon: 'icon-dashboard',
                to: '/account/reports/dashboard',
            },
            {
                label: t('Statistics'),
                icon: 'icon-chart-bar',
                to: '/account/reports/statistics',
            },
            {
                label: t('Security'),
                icon: 'icon-lock',
                to: '/account/security',
            },
            {
                label: t('Notification Settings'),
                icon: 'icon-bell',
                to: '/account/settings/notifications',
            },
            {
                label: t('Submission history'),
                icon: 'icon-direction',
                to: '/account/submission-history',
            },
            {
                label: t('Vulnerability submissions'),
                icon: 'icon-microchip',
                to: '/account/vulnerability-submissions',
            },
        ],
        [t]
    );

    const PAID_ONLY_ITEMS = ['/account/security'];
    const ORGANIZATION_ADMIN_ONLY_ITEMS = ['/account/settings/notifications'];

    const menuItemsProcessed = useMemo(() => {
        const isPaidLoggedIn = userContext.isPaidUser;
        const canAssessOrganization = userContext.isOrganizationAdmin;

        let menu = [...menuItems];
        if (!isPaidLoggedIn && !canAssessOrganization) {
            menu = menu.filter((list) => PAID_ONLY_ITEMS.indexOf(list.to) <= -1);
        }

        if (!canAssessOrganization) {
            menu = menu.filter((list) => ORGANIZATION_ADMIN_ONLY_ITEMS.indexOf(list.to) <= -1
            );
        }

        return menu;
    }, [userContext, menuItems]);

    useEffect(() => {
        if (userContext.state === userContext.STATES.LOGGED_OUT) {
            // If user hasn't log in yet, should redirect after login
            // If user logged in, then logout, don't need to redirect again (redirect to homepage instead)
            Cookie.set(COOKIE_OPSWAT_REDIRECT, loggedIn ? '' : windowLocation.href, {
                path: '/',
                secure: true,
            });
            window.location.href = '/login';
            return;
        }

        if (userContext.state === userContext.STATES.LOGGED_IN) {
            setLoggedIn(true);
        }

        const client = userContext && userContext.data;
        setUser(client);
    }, [userContext, loggedIn]);

    const PageFragment = useMemo(() => {
        switch (currentPage) {
            case 'submission-history':
                return <SubmissionHistory />;
            case 'vulnerability-submissions':
                return <VulnerabilitySubmissions />;
            case 'security':
                return <Security user={userContext} />;

            case 'reports':
                // eslint-disable-next-line react/prop-types
                if (!user?.sso_user_id || !['dashboard', 'statistics'].includes(params['*'])
                ) {
                    return null;
                }
                // eslint-disable-next-line react/prop-types
                return (
                    <AccountReportsLayout
                        user={user}
                        pageName={params['*']}
                        isOrganizationAdmin={userContext.isOrganizationAdmin}
                    />
                );
            case 'organization':
                // eslint-disable-next-line react/prop-types
                if (!['security'].includes(params['*'])) {
                    return null;
                }
                return <Security user={userContext} />;
            case 'settings':
                // eslint-disable-next-line react/prop-types
                if (!user?.sso_user_id || !user?.organization?.organization_id || !['notifications'].includes(params['*'])
                ) {
                    return null;
                }
                return <NotificationSettings />;

            default:
                if (!user) {
                    return null;
                }

                return <AccountInformation user={user} />;
        }
    }, [currentPage, user, authIsLoading, props, userContext]);

    useEffect(() => {
        setWindowLocation(window && window.location);
    }, []);

    const announcementBannerContent = (
        <p>
            {t(
                'Thanks for viewing our new dashboard & statistics! This feature is still in BETA so'
            )}{' '}
            <strong>
                {t('please do not use it for official business functions yet')}
            </strong>{' '}
            {t('(accounting, security operations, etc.)')}
        </p>
    );

    const announcementBannerDom = useMemo(() => {
        return (
            <AnnouncementBanner
                content={announcementBannerContent}
                className='custom'
                shouldExpireOn={'2021-10-01'}
            />
        );
    });

    if (!ready || authIsLoading) {
        return null;
    }

    return (
        <Layout
            className='account-page'
            pageTitle='Account'
            announcementBannerContent={announcementBannerDom}
            hasAnnouncementBanner={currentPage === 'reports'}
            pageDescription={'Account information'}
        >
            <SideBarPageLayout menuItems={menuItemsProcessed} pageName={currentPage}>
                {PageFragment}
            </SideBarPageLayout>
        </Layout>
    );
};

AccountPage.propTypes = {
    pageContext: PropTypes.object.isRequired,
    params: PropTypes.array,
};

export default AccountPage;
