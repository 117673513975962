const CUSTOM_KEY = 'custom';
export default (t) => ({
    SERVICES: [
        {
            label: t('File Scanning'),
            key: 'fileScan'
        },
        {
            label: t('Sandbox'),
            key: 'sandbox'
        },
        {
            label: t('File Reputation'),
            key: 'fileReputation'
        },
        {
            label: t('IP / Domain / URL Reputation'),
            key: 'address'
        }
    ],
    CUSTOM_KEY: CUSTOM_KEY,
    DEFAULT_RANGE_MODE: [
        {
            label: t('Last 24 hours'),
            key: '24h',
            hours: 24
        },
        {
            label: t('Last 7 days'),
            key: '7d',
            hours: 24 * 7
        },
        {
            label: t('Last 30 days'),
            key: '30d',
            hours: 24 * 30
        },
        {
            label: t('Last 1 year'),
            key: '1y',
            hours: 24 * 365
        },
        {
            label: t('Custom'),
            key: CUSTOM_KEY
        },
    ],
    DASHBOARD_DROPDOWN: [
        {
            label: t('Organizational Dashboard'),
            key: 'organization'
        },
        {
            label: t('My Dashboard'),
            key: 'personal'
        }
    ],
    STATISTICS_DROPDOWN: [
        {
            label: t('Organizational Statistics'),
            key: 'organization'
        },
        {
            label: t('My Statistics'),
            key: 'personal'
        }
    ],
});
